.apply {
  margin: 0 auto;
  justify-content: center;
  display: flex;

  .contact-form {
    width: 75%;
    height: 800px;
    padding: 20px;
    margin: 0 auto;
  }

  input,
  textarea {
    font-family: "Neutral Face";
    background-color: transparent;
    border-radius: 15px;
  }

  input[type="text"] {
    padding: 15px 10px;
    min-width: 800px;
  }

  input[type="email"] {
    padding: 15px 10px;
    min-width: 800px;
  }

  textarea {
    padding: 15px 10px;
    min-width: 800px;
    height: 300px;
  }
}

@media only screen and (max-width: 600px) {
  .contact-form {
    height: 400px !important;
    padding-top: 120px !important;
    height: 450px !important;
    max-width: 350px !important;
    max-height: 600px !important;
    z-index: 10000 !important;
  }

  .tf-v1-widget-close {
    top: 100px !important;
  }
}

@media screen and (max-width: 1500px) {
}

@media screen and (max-width: 1200px) {
}
