.home {
  margin: 0 auto;
  text-align: center;
  padding-top: 250px;
  display: flex;
  flex-direction: column;
  gap: 40px;
  width: 100%;

  .word,
  .studio {
    opacity: 0;
  }

  .landing h1 {
    font-size: 60px;
  }

  .landing span {
    padding: 10px;
    font-size: 60px;
  }

  .services li {
    font-size: 30px;
  }

  .services span {
    font-size: 30px;
  }

  .services ul {
    padding-top: 100px;
  }
}

@media only screen and (max-width: 600px) {
  .home {
    max-width: 350px;
    font-size: 25px;
    padding-top: 145px !important;

    .landing h1 {
      font-size: 20px;
    }

    .step span {
      max-width: 300px;
    }

    .landing span {
      font-size: 20px;
    }

    .services li {
      font-size: 15px !important;
    }
  }

  .step span {
    word-wrap: wrap;
  }
}

@media only screen and (max-width: 900px) {
  .home {
    padding-top: 180px;

    .landing h1 {
      padding: 20px;
      font-size: 20px;
    }
  }
}

@media screen and (max-width: 1500px) {
  .home {
    padding-top: 50px;
    font-size: 30px;

    .landing h1 {
      padding: 20px;
      font-size: 40px;
    }
  }
}

@media screen and (max-width: 1200px) {
  .home {
    padding-top: 80px;

    .landing h1 {
      padding: 20px;
      font-size: 20px;
    }

    .services ul {
      padding-top: 50px;
    }

    .services li {
      font-size: 20px;
    }
  }
}
