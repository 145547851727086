.header {
  height: 60px;
  background-color: black;
  position: sticky;
  top: 0;
  display: flex;
  justify-content: space-between;
  font-size: 12px;

  .header-left {
    padding-top: 20px;
    padding-left: 20px;
  }

  .header-center {
    padding-top: 20px;
  }

  .header-right {
    display: flex;
    padding-right: 20px;
    padding-top: 20px;
    gap: 20px;
  }
}
