* {
  font-family: "Darker Grotesque";
  font-size: 16px;
}

body {
  background-color: black;
  color: #FFFFFF;
  overflow-y: hidden;
  overflow-x: hidden;
}

body::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
body {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}


@font-face {
  font-family: 'Neutral Face';
  src: url("./assets/fonts/NeutralFace.otf");
}

@font-face {
  font-family: 'Neutral Face Bold';
  src: url("./assets/fonts/NeutralFace-Bold.otf");
}

@font-face {
  font-family: 'Darker Grotesque';
  src: url("./assets/fonts/DarkerGrotesque.ttf");
}
