@import url('https://fonts.googleapis.com/css2?family=Courier+Prime&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  * {
    @apply font-main;
  }
  .menu a {
    @apply font-body;
  }
}