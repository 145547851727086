.footer {
  height: 30px;
  color: white;
  z-index: 1000000000000000 !important;
  position: absolute;
  bottom: 10px;
  padding-left: 15px;
  width: 100%;
  mix-blend-mode: difference;
  font-size: 12px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .footer-right {
    padding-right: 20px;
  }
}
